import { type Component, createMemo, For, Show } from "solid-js";

type LengthCutVisualProps = {
  cuts?: number | (number | undefined)[];
  stickLength?: number;
};

export const LengthCutVisual: Component<LengthCutVisualProps> = (props) => {
  const stickLength = () => props.stickLength || 108;

  const cuts = createMemo(() => {
    if (!props.cuts) return [];
    if (typeof props.cuts === "number") return [props.cuts];
    return props.cuts;
  });

  // Calculate segments based on cuts
  const segments = createMemo(() => {
    const inputCuts = cuts();
    const total = stickLength();
    const result = [];

    if (inputCuts.length === 0) {
      result.push({ width: 100, measurement: total });
      return result;
    }

    // Handle each cut position
    for (let i = 0; i < inputCuts.length; i++) {
      const cutPosition = inputCuts[i] ?? 0;

      if (cutPosition > total || cutPosition <= 0) continue;

      const widthPercent = (cutPosition / total) * 100;

      result.push({
        width: widthPercent,
        measurement: cutPosition,
      });
    }

    return result;
  });

  const balance = () =>
    stickLength() -
    (segments()[0]?.measurement || 0) -
    (segments()[1]?.measurement || 0);

  return (
    <div>
      <div class="flex w-full h-5 bg-roma-grey border border-roma-medium-grey rounded-sm overflow-hidden">
        <For each={segments()}>
          {(segment, index) => (
            <div
              class={`h-full border-r border-gray-900 flex items-center justify-center ${
                index() % 2 === 0 ? "bg-gray-300" : "bg-gray-200"
              } transition-[width]`}
              style={{ width: `${segment.width}%` }}
            >
              <span class="text-xs fraction">{segment.measurement}"</span>
            </div>
          )}
        </For>
        <Show
          when={
            segments()[0].width &&
            Math.floor(
              stickLength() -
                (segments()[0]?.width || 0) -
                (segments()[1]?.width || 0)
            ) > 0
          }
        >
          <div class="grow flex items-center justify-center text-center fraction text-xs relative">
            <Show when={balance() >= 10}>
              <span class="absolute right-1">Balance</span>
            </Show>
          </div>
        </Show>
      </div>
      <Show when={stickLength() >= 108}>
        <p class="text-xs font-light italic mt-1">
          Based on average stick length of 108"
        </p>
      </Show>
    </div>
  );
};
