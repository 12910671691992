import { type Component, createSignal, createEffect, Show } from "solid-js";
import { SelectBox } from "~/components/inputs";
import { LengthCutVisual } from "./LengthCutVisual";
import { Motion } from "solid-motionone";

type LengthCustomCutsProps = {
  onChange: (cuts: any[]) => void;
  stickLength?: () => number;
  initializeWith?: number[];
  class?: string;
};

export const LengthCustomCuts: Component<LengthCustomCutsProps> = (props) => {
  const [cut1, setCut1] = createSignal<number>(props.initializeWith?.[0] ?? 0);
  const [cut2, setCut2] = createSignal<number>(props.initializeWith?.[1] ?? 0);

  const stickLength = () => props.stickLength?.() || 108;

  const getRange = (n: number) => {
    const end = stickLength() - 1 - n;
    return Array.from({ length: end - 3 }, (_, i) => i + 4);
  };

  createEffect(() => {
    props.onChange([
      !!cut1() ? cut1() : undefined,
      !!cut2() ? cut2() : undefined,
    ]);
  });

  return (
    <section
      class="border bg-roma-grey p-4 flex flex-col gap-4 text-sm"
      classList={{ [`${props.class}`]: !!props.class }}
    >
      <p>
        Specify up to 2 positions where you would like straight cuts made in the
        moulding length.
      </p>
      <div class="flex p-4 bg-white border rounded-md gap-4 w-full flex-col  ">
        <div class="flex gap-4">
          <SelectBox
            label={
              <div class="flex justify-between">
                <span>Cut One</span>
                <Show when={!!cut1()}>
                  <button
                    onClick={() => setCut1(0)}
                    class="text-xs text-roma-blue mr-2"
                  >
                    Clear
                  </button>
                </Show>
              </div>
            }
            class="w-full"
            triggerClass="bg-white"
            options={getRange(cut2() || 0).map((n) => ({
              value: n,
              label: n + `"`,
            }))}
            value={{ value: cut1() }}
            onChange={(option) => setCut1(option.value as number)}
            placeholder="First Cut"
          />
          <SelectBox
            label={
              <div class="flex justify-between">
                <span>Cut Two</span>
                <Show when={!!cut2()}>
                  <button
                    onClick={() => setCut2(0)}
                    class="text-xs text-roma-blue mr-2"
                  >
                    Clear
                  </button>
                </Show>
              </div>
            }
            class="w-full"
            triggerClass="bg-white"
            options={getRange(cut1() || 0).map((n) => ({
              value: n,
              label: n + `"`,
            }))}
            value={{ value: cut2() }}
            onChange={(option) => setCut2(option.value as number)}
            placeholder="Second Cut"
            disabled={!cut1()}
          />
        </div>
        <Show when={cut1()}>
          <Motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <LengthCutVisual
              cuts={[cut1(), cut2()]}
              stickLength={stickLength()}
            />
          </Motion.div>
        </Show>
      </div>
    </section>
  );
};
